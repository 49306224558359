import React from "react";
import LargeCVEntry from "../components/LargeCVEntry";

const Awards = () => {
  return (
    <div className="background">
      <div className="container items-center flex-col flex">
        <h1 className="page-heading">Honors &amp; Awards</h1>
        <LargeCVEntry
          title="German Academic Scholarship Foundation"
          subtitle="Scholarship Holder"
          time=""
          location=""
          icon="img/studienstiftung.png"
        >
          Scholarship of the "Studienstiftung des Deutschen Volkes" awarded in
          recognition of excellent academic achievements and extracurricular
          engagement. <br />
          <i>Less than 0.5% of German students are awarded this sholarship.</i>
        </LargeCVEntry>
        <LargeCVEntry
          title="TUM: Junge Akademie"
          subtitle="Scholarship Holder"
          time=""
          location=""
          icon="img/tumja.png"
        >
          <i>
            Scholarship of the "TUM: Junge Akademie" awarded to "exceptionally
            talented students"
          </i>
          <br />
          Selected as one of 42 students for the class of 2020
        </LargeCVEntry>
      </div>
    </div>
  );
};

export default Awards;
