import React from "react";
import CVEntry from "../components/CVEntry";

const Education = () => {
  return (
    <div className="background">
      <div className="container items-center flex-col flex">
        <h1 className="page-heading">Education</h1>
        <CVEntry
          title="Swiss Federal Institute of Technology (ETH Zürich)"
          subtitle="MSc. Computer Science, (Expected) Distinction, GPA: 5.86/6.00"
          time="2022 – 2024"
          location="Zürich, Switzerland"
          icon="img/eth.png"
        >
          <li>Major in Data Management Systems, Minor in Machine Learning</li>
          <li>
            Cross-Registration at the EPFL, GPA: 6.00/6.00 <br />
          </li>
          <li>Focus on Systems, Hardware Acceleration, and Algorithms</li>
        </CVEntry>
        <CVEntry
          title="Stanford University"
          subtitle="Visiting Student Researcher"
          time="2024-2024"
          location="Stanford, CA"
          icon="img/stanford.png"
        >
          <li>Optimized end-to-end latency and throughput of DeepMind’s XTR</li>
          <li>Courses on Compilers, Operating Systems, and Transformers</li>
          <li>Stay funded by the German Academic Scholarship Foundation</li>
        </CVEntry>
        <CVEntry
          title="Technical University Munich (TUM)"
          subtitle="B.Sc. Computer Science, High Distinction, GPA 1.1"
          time="2018 – 2021"
          location="Munich, Germany"
          icon="img/tum.png"
        >
          <li>
            Thesis:{" "}
            <i>Time Series Data Mining using Matrix Profiles on FPGAs</i>
          </li>
          <li>Focus on C++, Operating Systems, and Computer Architecture</li>
        </CVEntry>
      </div>
    </div>
  );
};

export default Education;
