import React from "react";
import "./App.css";
import TitlePage from "./pages/TitlePage";
import Education from "./pages/Education";
import Awards from "./pages/Awards";
import Experience from "./pages/Experience";
import Projects from "./pages/Projects";
import ReactTooltip from "react-tooltip";

const App = () => {
  return (
    <div className="App text-neutral-300">
      <TitlePage />
      <Experience />
      <Education />
      <Projects />
      <Awards />
      <ReactTooltip multiline={true} />
    </div>
  );
};

export default App;
